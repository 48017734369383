<template>
  <div>
    <v-autocomplete
      v-model="tags"
      :items="productTags"
      outlined
      dense
      chips
      small-chips
      label="Product Tags"
      multiple
      hide-details
      class="mb-4"
      append-outer-icon="mdi-plus"
      @click:append-outer="AddNewTag()"
    >
      <template v-slot:selection="data">
        <v-chip
          small
          v-bind="data.attrs"
          @click.prevent="data.select, AddNewTag(data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
    </v-autocomplete>
    <v-dialog
      v-model="dialogTags.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card v-if="dialogTags.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogTags.data.id ? 'Update Tag' : 'Create New Tag' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <div class="py-2" style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogTags.data.image"
              aspect-ratio="1"
              class="blue lighten-4 fill-width"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogTags.data.image ? '+ Change Image' : '+ Add Image' }}<br>
                1:1
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogTags.data.name"
            outlined
            dense
            hide-details
            label="Nama Tag"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogTags.data.tag"
            outlined
            dense
            hide-details
            label="Tag (tanpa spasi)"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model.number="dialogTags.data.menu"
            :items="[
              { name: 'Yes', value: 1 },
              { name: 'No', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Menu ?"
          />
          <v-text-field
            v-model.number="dialogTags.data.menusorting"
            outlined
            dense
            hide-details
            label="Sorting Asc"
            class="mb-4 rounded-0"
            @keypress="$NumberOnly"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogTags.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="TAGS_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    tags: [],
    loadingImageUpload: false,
    dialogTags: {
      show: false,
      data: null
    }
  }),
  computed: {
    productTags () {
      const tags = []
      const x = this.$store.state.product.productTags
      for (let t = 0; t < x.length; t++) {
        tags.push(x[t].tag)
      }
      return tags
    },
    ...mapState({
      tagsItems: state => state.product.productTags
    })
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        // console.log('triggered', v)
        this.tags = v || []
      }
    },
    tags (v) {
      this.$emit('input', v)
    }
  },
  mounted () {
    // console.log(this.value, this.productTags)
  },
  methods: {
    AddNewTag (tag) {
      if (typeof tag === 'string') {
        const find = this.tagsItems.find((r) => r.tag === tag)
        if (!find) {
          this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          return false
        }
        this.dialogTags.data = Object.assign({}, find)
      } else if (typeof tag === 'object') {
        this.dialogTags.data = Object.assign({}, tag)
      } else {
        this.dialogTags.data = Object.assign({}, {
          id: null,
          name: '',
          store: this.$store.state.storeActive,
          image: '',
          tag: '',
          menu: 0,
          menusorting: 1,
          menuparent: 0
        })
      }
      this.dialogTags.store = this.$store.state.storeActive || 0
      this.dialogTags.show = true
    },
    TAGS_PROCESS () {
      const newTag = Object.assign({}, this.dialogTags.data)
      if (!newTag.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama tag belum diisi!' })
        return false
      }
      if (!newTag.tag) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tag belum diisi!' })
        return false
      }
      this.$store.dispatch('product/PRODUCT_TAGS_PROCESS', newTag)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('product/PRODUCT_TAGS_GET')
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil dibuat!' })
            this.dialogTags.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage () {
      // type="file"
      // class="file-uploader"
      // accept=".png,.gif,.jpeg,.jpg"
      // @change=""
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'tags'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogTags.data.image = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
